<template>
  <div class="error-content align-center">
    <div class="error-center">
      <img src="~assets/images/auth/500.png" alt="" v-if="errorInfo.code_num > 500" class="error" />
      <img src="~assets/images/auth/404.png" alt="" v-else-if="errorInfo.code_num === 404" class="error" />
      <img src="~assets/images/auth/403.png" alt="" v-else-if="errorInfo.code_num === 404" class="error" />
      <img src="~assets/images/auth/500.png" alt="" v-else class="error" />
      <div class="error-right">
        <div class="num">{{ errorInfo.code_num }}</div>
        <div class="text">
          {{ errorInfo.error_text }} <span class="time">{{ time }} 返回首页</span>
        </div>
        <el-button class="btn" @click="$router.replace('/home')">返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {
      auth_keep_status: false,
      time: 5,
      timer: null,
    };
  },
  created() {
    this.setTime();
  },
  methods: {
    setTime() {
      this.timer = setInterval(() => {
        if (this.time === 0) {
          clearInterval(this.timer);
          this.$router.replace('/home');
          return;
        }
        this.time--;
      }, 1000);
    },
  },
  computed: {
    errorInfo() {
      return this.$store.getters.errorInfo;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/error/index.less';
.time {
  font-size: 14px;
  color: #f01414;
}
</style>
